import {
  useEffect, useRef, useState,
} from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { User } from '@api/gql/graphql'
import { restEndpoint, RestEndpointResponse } from '@api/rest-endpoint'
import logo from '@assets/logo.svg'
import { toast } from '@components/toast'
import { TopMenuItem } from '@constants/analytic'
import { getLoginUrl } from '@constants/link'
import {
  faArrowRightFromBracket,
  faBox,
  faChevronLeft,
  faPlus,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { feedback } from '@helpers/feedback'
import { ga } from '@helpers/ga'
import { sentry } from '@helpers/sentry'
import { tokenStore } from '@helpers/token-store'
import { ProfileButton } from '@layouts/components/profile-button'
import { useHeaderContext } from '@layouts/context/header-context'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import { Button } from '@nextui-org/button'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/dropdown'
import { Link } from '@nextui-org/link'
import { Spinner } from '@nextui-org/spinner'
import { useIsMobile } from '@nextui-org/use-is-mobile'

type Props = {
  onBack?: () => void
}

export const Header = (props?: Props) => {
  const { openModal } = useModal()
  const headerContext = useHeaderContext()
  const isMobile = useIsMobile()

  const [visibleMenu, setVisibleMenu] = useState<boolean>(false)
  const [profile, setProfile] = useState<User | null>(null)
  const fetched = useRef<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (fetched.current || !tokenStore.get()) {
      return
    }

    setLoading(true)

    fetched.current = true

    restEndpoint
      .get<RestEndpointResponse<User>, any>('/profile')
      .then((result) => {
        setProfile(result.content)

        amplitude.setUser(result.content)
        ga.setUser(result.content)
        sentry.setUser(result.content)
        feedback.setUser(result.content)
      })
      .catch((e) => {
        if (e.httpStatus !== 401) {
          toast.error(e.message)
        }
      }).finally(() => {
        setLoading(false)
      })
  }, [])

  const logout = () => {
    restEndpoint
      .post('/logout')
      .then(() => {
        setProfile(null)
        tokenStore.remove()

        amplitude.event({ type: AmplitudeEvent.LogoutClick })
        window.location.href = '/'
      })
      .catch((e) => {
        toast.error(e.message)
      })
  }

  const onLogoutWithModal = async () => {
    openModal({
      type: ModalType.CONFIRM_MODAL,
      title: 'Log out of your account?',
      message: '',
      onCancel: () => {},
      onConfirm: logout,
    })
  }

  const onOpenMenu = () => {
    if (!profile) {
      window.location.href = getLoginUrl()
    } else {
      setVisibleMenu(true)
    }
  }

  return (
    <div className="grid grid-cols-10 md:grid-cols-3 mb-4 md:mb-14 pr-4 lg:px-0">
      <div className="col-span-2 md:col-span-1">
        {!!headerContext.backPath && !headerContext.useNavigate && (
          <Button
            as={Link}
            href={headerContext.backPath}
            variant="light"
            className="w-full md:w-fit flex justify-start pl-4"
            isIconOnly
            startContent={<FontAwesomeIcon icon={faChevronLeft} className="text-gray-400" />}
          />
        )}

        {!!headerContext.backPath && headerContext.useNavigate && (
          <Button
            as={RouterLink}
            to={headerContext.backPath}
            variant="light"
            className="w-full md:w-fit flex justify-start pl-4"
            isIconOnly
            startContent={<FontAwesomeIcon icon={faChevronLeft} className="text-gray-400" />}
          />
        )}

        {!!props?.onBack && (
          <Button
            variant="light"
            className="w-full md:w-fit flex justify-start pl-4"
            isIconOnly
            startContent={<FontAwesomeIcon icon={faChevronLeft} className="text-gray-400" />}
            onClick={props?.onBack}
          />
        )}
      </div>

      {headerContext.title && isMobile
        ? (
          <div className="text-base font-medium flex justify-center items-center col-span-6 md:col-span-1">
            {headerContext.title}
          </div>
        )
        : (
          <Link className="flex justify-center col-span-6 md:col-span-1" href="/">
            <img src={logo} alt="Logo" className="object-contain max-w-40 lg:max-w-60" />
          </Link>
        )}

      <div className="flex justify-end col-span-2 md:col-span-1">
        <Dropdown size="lg" isOpen={visibleMenu && !!profile} onOpenChange={setVisibleMenu}>
          <DropdownTrigger>
            <button
              type="button"
              className="flex outline-none gap-3 items-center hover:opacity-80 cursor-pointer transition-opacity"
              onClick={onOpenMenu}
            >
              {loading
                ? <Spinner color="white" size="sm" />
                : (
                  <ProfileButton profile={profile} />
                )}
            </button>
          </DropdownTrigger>

          <DropdownMenu
            variant="faded"
            aria-label="Dropdown menu with description"
          >
            <DropdownItem
              key="new-album"
              as={Link}
              className="text-black"
              href="/create"
              onClick={() => {
                amplitude.event({
                  type: AmplitudeEvent.TopMenuSelectMenuItem,
                  name: TopMenuItem.NewAlbum,
                })
              }}
              startContent={<FontAwesomeIcon icon={faPlus} />}
            >
              New album
            </DropdownItem>

            <DropdownItem
              key="orders"
              as={Link}
              className="text-black"
              href="/orders"
              onClick={() => {
                amplitude.event({
                  type: AmplitudeEvent.TopMenuSelectMenuItem,
                  name: TopMenuItem.Orders,
                })
              }}
              startContent={<FontAwesomeIcon icon={faBox} />}
            >
              Orders
            </DropdownItem>

            <DropdownItem
              key="profile"
              as={Link}
              className="text-black"
              href="/profile"
              onClick={() => {
                amplitude.event({
                  type: AmplitudeEvent.TopMenuSelectMenuItem,
                  name: TopMenuItem.Profile,
                })
              }}
              startContent={<FontAwesomeIcon icon={faUser} />}
            >
              Profile
            </DropdownItem>

            <DropdownItem
              key="logout"
              as="button"
              className="text-black text-left"
              startContent={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
              onClick={(e) => {
                e.preventDefault()
                onLogoutWithModal()
              }}
            >
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  )
}
