import { memo, SVGProps } from 'react'

import { Props } from './types'

export const SvgQuestion = memo((props: SVGProps<SVGSVGElement> & Props) => (
  <svg
    width={props.size}
    height={props.size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <g clipPath="url(#question_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 99.997c27.614 0 50-22.386 50-50 0-27.615-22.386-50-50-50-27.615 0-50.001 22.385-50.001 50 0 27.614 22.386 50 50 50zm1.559-40.468c-2.228 0-4.076-1.848-4.076-4.076v-1.141c0-6.23 4.51-9.329 6.296-10.555l.063-.043c2.01-1.36 2.663-2.283 2.663-3.696 0-2.718-2.229-4.946-4.946-4.946-2.717 0-4.946 2.228-4.946 4.946 0 2.228-1.848 4.076-4.076 4.076-2.228 0-4.076-1.848-4.076-4.076 0-7.229 5.87-13.099 13.098-13.099 7.228 0 13.098 5.87 13.098 13.099 0 6.196-4.565 9.293-6.25 10.435-2.12 1.413-2.772 2.337-2.772 3.859v1.14a4.07 4.07 0 0 1-4.076 4.077zm.004 13.539a4.07 4.07 0 0 1-4.076-4.076c0-2.229 1.848-4.076 4.076-4.076 2.228 0 4.076 1.847 4.076 4.076a4.07 4.07 0 0 1-4.076 4.076z"
        fill="currentColor"
      />
    </g>
  </svg>
))
