import { ModalType } from '@modals/_types'

import { OrderEvent } from '../order-events'

export enum HintType {
  READY = 'ready',
  BLANK_SPACES = 'blank-spaces',
  AWESOME = 'awesome',
}

export const defaultHintType = HintType.READY

type Hint = {
  type: HintType
  orderEvents: OrderEvent[]
  message: string
  modalType?: ModalType.SELECT_ALBUM_COVER_MODAL | ModalType.ALBUM_PHOTOS_MODAL
}

export const hints: Hint[] = [
  {
    type: HintType.READY,
    orderEvents: [OrderEvent.IMAGE_DELETED],
    message: 'Your book is ready! Review the content and remove unwanted photos',
    modalType: ModalType.ALBUM_PHOTOS_MODAL,
  },
  {
    type: HintType.BLANK_SPACES,
    orderEvents: [],
    message: 'Blank spaces in your book? Try to reload the page',
  },
  {
    type: HintType.AWESOME,
    orderEvents: [OrderEvent.COVER_EDITED],
    message: 'Your book looks awesome! Pick photos for the cover',
    modalType: ModalType.SELECT_ALBUM_COVER_MODAL,
  },
]
