import {
  createContext, ReactNode, useContext, useMemo, useState,
} from 'react'

export type State = {
  title?: string
  backPath?: string
  useNavigate?: boolean
}

type HeaderContextProps = {
  setTitle: (title: string) => void
  setBackPath: (backPath: string, useNavigate?: boolean) => void
}

// В мобильной версии навигация по шапке отличается
// поэтому есть такой контекст который принимает модификации
export const HeaderContext = createContext<HeaderContextProps & State>({
  setTitle: () => {},
  setBackPath: () => {},
})

type ProviderProps = {
  children: ReactNode
  initialState: State
}

export const HeaderContextProvider = (props: ProviderProps) => {
  const [state, setState] = useState<State>(props.initialState)

  const value = useMemo((): HeaderContextProps => ({
    ...state,
    setTitle: (v) => setState((s) => ({ ...s, title: v })),
    setBackPath: (v, useNavigate: boolean = false) => setState((s) => ({
      ...s, backPath: v, useNavigate,
    })),
  }), [state])

  const children = useMemo(() => props.children, [props.children])

  return (
    <HeaderContext.Provider value={value}>
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeaderContext = () => {
  return useContext(HeaderContext)
}
