import {
  createContext, FC, ReactNode, useMemo, useState,
} from 'react'

type HintContextState = {
  showTimestamp: number
  onShow: () => void
}

export const HintContext = createContext<HintContextState>({
  showTimestamp: 0,
  onShow: () => {},
})

export const HintContextProvider: FC<{ children: ReactNode }> = (props) => {
  const children = useMemo(() => props.children, [props.children])

  const [showTimestamp, setShowTimestamp] = useState<number>(0)

  const value = useMemo(():HintContextState => ({
    showTimestamp,
    onShow: () => {
      setShowTimestamp(new Date().getTime())
    },
  }), [showTimestamp])

  return (
    <HintContext.Provider value={value}>
      {children}
    </HintContext.Provider>
  )
}
