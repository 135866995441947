import { lazy } from 'react'

import { ModalType } from './_types'
import { AlertModal } from './alert-modal'
import { ConfirmModal } from './confirm-modal'
import { LoginModal } from './login-modal'

// Некоторые могут весить много или содержать недоступные в статике библиотеки
// В этом случае импортируем их лениво
export const loadComponents = () => ({
  [ModalType.ADMIN_ORDER_MODAL]: lazy(() => import('./admin-order-modal/admin-order-modal')),
  [ModalType.SELECT_ALBUM_COVER_MODAL]: lazy(() => import('@modals/select-album-cover-modal/select-album-cover-modal')),
  [ModalType.EDIT_ALBUM_METADATA_MODAL]: lazy(() => import('@modals/edit-album-metadata-modal/edit-album-metadata-modal')),
  [ModalType.MOVE_IMAGE_MODAL]: lazy(() => import('@modals/move-image-modal/move-image-modal')),
  [ModalType.UPDATE_COMMENT_MODAL]: lazy(() => import('@modals/update-comment-modal/update-comment-modal')),
  [ModalType.ALBUM_PAGE_MODAL]: lazy(() => import('@modals/album-page-modal/album-page-modal')),
  [ModalType.ALBUM_PHOTOS_MODAL]: lazy(() => import('@modals/album-photos-modal/album-photos-modal')),
  [ModalType.UPDATE_ALBUM_LAYOUT_MODAL]: lazy(() => import('@modals/update-album-layout-modal/update-album-layout-modal')),
  [ModalType.LOGIN_MODAL]: LoginModal,
  [ModalType.ALERT_MODAL]: AlertModal,
  [ModalType.CONFIRM_MODAL]: ConfirmModal,
})
