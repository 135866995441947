import hotToast, { Toaster } from 'react-hot-toast'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@nextui-org/button'

import { Opts } from './constants'

const error = (message: string, opts?: Opts) => hotToast(
  message,
  { style: { backgroundColor: '#FFF', color: '#000' }, icon: '❌', ...opts },
)

const success = (message: string, opts?: Opts) => hotToast(
  message,
  { style: { backgroundColor: '#FFF', color: '#000' }, icon: '✅', ...opts },
)

const info = (message: string, opts?: Opts) => hotToast(
  message,
  { style: { backgroundColor: '#FFF', color: '#000' }, icon: 'ℹ️', ...opts },
)

const warning = (message: string, opts?: Opts) => hotToast(
  message,
  { style: { backgroundColor: '#FFF', color: '#000' }, icon: '👀', ...opts },
)

const infoButton = (
  title: string,
  opts?: Opts & {
    buttonTitle: string,
    onClick: () => void,
    onDismiss: () => void,
  },
) => {
  hotToast.dismiss()

  hotToast(
    (t) => (
      <div className="flex items-center gap-2">
        <div className="text-base">{title}</div>
        <div className="flex gap-1">
          <Button onClick={() => {
            hotToast.dismiss(t.id)
            opts?.onClick()
            opts?.onDismiss()
          }}
          >
            {opts?.buttonTitle}
          </Button>

          <Button
            onClick={() => {
              hotToast.dismiss(t.id)
              opts?.onDismiss()
            }}
            isIconOnly
            variant="light"
            startContent={<FontAwesomeIcon icon={faClose} />}
          />
        </div>
      </div>
    ),
    {
      style: { backgroundColor: '#FFF', color: '#000' }, ...opts,
    },
  )
}

export const toast = {
  error,
  success,
  info,
  infoButton,
  warning,
}

export const ToastPlacement = () => <Toaster position="top-center" />
