import { memo, SVGProps } from 'react'

import { Props } from './types'

export const SvgReview = memo((props: SVGProps<SVGSVGElement> & Props) => (
  <svg
    width={props.size}
    height={props.size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 2h50c15 0 25 10 25 25v30c0 15-10 25-25 25H55L32.75 96.8c-3.3 2.25-7.75-.15-7.75-4.15V82C10 82 0 72 0 57V27C0 12 10 2 25 2zm32 27.846-3.525-7.098c-1.925-3.851-5.025-3.851-6.95-.025l-3.526 7.097c-.474.982-1.75 1.938-2.825 2.115L33.8 32.992c-4.075.704-5.025 3.674-2.1 6.62l4.975 5.008c.825.83 1.3 2.467 1.075 3.675l-1.425 6.191c-1.125 4.883 1.475 6.77 5.75 4.229l5.976-3.574c1.075-.655 2.875-.655 3.95 0l5.975 3.574c4.276 2.567 6.876.654 5.75-4.229l-1.424-6.191c-.275-1.183.2-2.82 1.025-3.65l4.975-5.009c2.925-2.97 1.975-5.94-2.1-6.62l-6.375-1.056c-1.075-.202-2.35-1.133-2.825-2.114z"
      fill="currentColor"
    />
  </svg>
))
