import {
  FC, ReactNode, Suspense, useCallback, useMemo, useState,
} from 'react'
import { v4 } from 'uuid'

import { loadComponents } from '../_component'
import { OpenModalProps } from '../_types'

import { Context, ModalContextState } from './context'

type ModalState = {
  uuid: string
  isOpen: boolean
}

type ModalItem = ModalState & OpenModalProps

const components = loadComponents()

export const ModalContextProvider: FC<{ children: ReactNode }> = (props) => {
  const [modalItems, setModalItems] = useState<ModalItem[]>([])

  const value = useMemo((): ModalContextState => ({
    openModal: (modalItem) => {
      setModalItems((prevState) => [...prevState, {
        ...modalItem,
        uuid: v4(),
        isOpen: true,
      }])
    },
  }), [modalItems])

  const closeModal = useCallback((uuid: string) => {
    return () => {
      setModalItems((prevState) => prevState.map((modalItem) => {
        return modalItem.uuid === uuid ? { ...modalItem, isOpen: false } : modalItem
      }))

      const t = setTimeout(() => {
        clearTimeout(t)
        setModalItems((prevState) => prevState.filter((modal) => modal.uuid !== uuid))
      }, 200)
    }
  }, [modalItems])

  const children = useMemo(() => props.children, [props.children])

  return (
    <Context.Provider value={value}>
      {children}
      {modalItems.map((modalItem) => {
        const Component = components[modalItem.type] as any
        return (
          <Suspense key={modalItem.uuid}>
            <Component
              {...modalItem}
              isOpen={modalItem.isOpen}
              onClose={closeModal(modalItem.uuid)}
            />
          </Suspense>
        )
      })}
    </Context.Provider>
  )
}
