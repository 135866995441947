import { useContext } from 'react'
import { useLocation } from 'react-router'
import { SvgQuestion, SvgReview } from '@components/icons'
import { feedback } from '@helpers/feedback'
import { HintContext } from '@helpers/hints'
import { clsx } from 'clsx'

export const FixedButtons = () => {
  const hint = useContext(HintContext)
  const location = useLocation()

  const isShowHintButton = location.pathname.match('/orders')

  return (
    <div
      className={clsx(
        'fixed flex flex-col bg-white rounded-full z-40 right-1 bottom-[50%] gap-2.5 p-2 shadow md:shadow-none',
        'md:bg-transparent md:rounded-none md:flex-row md:right-0 md:p-4 md:bottom-0 md:gap-0',
      )}
    >
      {isShowHintButton && (
        <div className="p-1 md:p-2" onClick={hint.onShow}>
          <SvgQuestion
            size={25}
            className="opacity-80 hover:opacity-100 transition-opacity cursor-pointer text-gray-blue"
          />
        </div>
      )}

      <div className="p-1 md:p-2" onClick={feedback.open}>
        <SvgReview
          size={25}
          className="opacity-80 hover:opacity-100 transition-opacity cursor-pointer text-gray-blue"
        />
      </div>
    </div>
  )
}
